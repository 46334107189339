<!--
 * @Description: 推荐商品
 * @Author: 琢磨先生
 * @Date: 2022-06-10 15:51:46
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-09-14 03:22:58
-->

<template>
  <el-card class="box">
    <el-button type="primary" size="small" round icon="plus" @click="onEdit()"
      >新增</el-button
    >
  </el-card>
  <el-card class="box" v-loading="loading">
    <el-table :data="dataList" border>
      <el-table-column label="图片" width="140">
        <template #default="scope">
          <el-image
            :src="scope.row.logo_url"
            style="width: 100px; height: 100px"
            fit="fill"
          ></el-image>
        </template>
      </el-table-column>

      <el-table-column label="标题" prop="title"></el-table-column>

      <el-table-column label="价格" width="140">
        <template #default="scope">
          <div>一口价:{{ scope.row.price }}</div>
          <div>规格最低价:{{ scope.row.sku_min_price }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="序号"
        prop="sequence"
        width="100"
      ></el-table-column>
      <el-table-column width="180" label="操作">
        <template #default="scope">
          <el-popconfirm
            v-if="!scope.row.is_super"
            title="您确定要删除？"
            @confirm="onDelete(scope.row)"
          >
            <template #reference>
              <el-button size="small" type="danger" link class="btn-del"
                >删除</el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </el-card>

  <el-dialog
    :title="title"
    v-model="dialogVisible"
    width="600px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      :model="form"
      ref="form"
      :rules="rules"
      label-width="140px"
      :inline="false"
    >
      <el-form-item label="选择商品" prop="goods_id">
        <el-col :span="20">
          <el-select popper-class="select-goods"
            style="width: 100%"
            v-model="form.goods_id"
            placeholder="输入关键字搜索并选择"
            remote
            filterable
            :remote-method="remoteGoods"
          >
            <el-option
              :label="item.title"
              :value="item.id"
              v-for="item in goodsList"
              :key="item.id"
            >
              <div class="flex-row item-center">
                <b>{{ item.id }}、</b>
                <el-image
                  :src="item.logo_url"
                  style="width: 50px; height: 50px"
                  fit="fill"
                ></el-image>
                <span>{{ item.title }}</span>
              </div>
            </el-option>
          </el-select></el-col
        >
      </el-form-item>

      <el-form-item label="序号(倒序)" prop="sequence">
        <el-col :span="10">
          <el-input v-model="form.sequence"></el-input>
        </el-col>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving"
        >确 定</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loading: false,
      saving: false,
      dialogVisible: false,
      title: "",
      //商品列表
      goodsList: [],
      //列表
      dataList: [],
      form: {},
      rules: {
        goods_id: [{ required: true, message: "请输入", trigger: "blur" }],
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.$http.get("admin/v1/recgoods").then((res) => {
        if (res.code == 0) {
          this.dataList = res.data;
        }
        this.loading = false;
      });
    },

    /**
     * 新增、修改
     */
    onEdit(item) {
      this.title = item ? "修改轮播" : "新增轮播";
      this.form = item ? Object.assign({}, item) : {};
      if (this.form.area_id) {
        this.loadBusinessCircle();
      }
      this.dialogVisible = true;
    },

    /**
     * 提交保存
     */
    onSubmit() {
      this.saving = true;
      this.$http.post("admin/v1/recgoods/edit", this.form).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.loadData();
        }
        this.saving = false;
        this.dialogVisible = false;
      });
    },

    /**
     * 删除
     */
    onDelete(item) {
      this.$http.get("admin/v1/recgoods/del?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.loadData();
        }
      });
    },
    /**
     * 关键字搜索商品
     */
    remoteGoods(q) {
      this.$http.get("admin/v1/common/select/goods?q=" + q).then((res) => {
        if (res.code == 0) {
          this.goodsList = res.data;
        }
      });
    },
  },
};
</script>
<style >
.select-goods .el-select-dropdown__item{
    line-height: normal;
    height: auto;
}
</style>